<script lang="ts" setup>
const { $TolgeeProvider } = useNuxtApp();
const { getLocale } = useLanguage();
</script>

<template>
  <a-config-provider :locale="getLocale()">
    <component :is="$TolgeeProvider">
      <a-layout>
        <slot name="navbar" />
        <a-layout>
          <a-layout-content class="pl-4 pr-4 pb-4 h-full">
            <a-row class="flex align-middle">
              <a-col class="flex-none sm:flex-auto">
                <slot name="title" />
              </a-col>
              <a-col class="w-full sm:w-auto">
                <slot name="operations" />
              </a-col>
            </a-row>
            <slot name="content" class="h-full" />
            <slot class="h-full" />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </component>
  </a-config-provider>
</template>
